import { UnsubscribeModule } from "../Modules/UnsubscribeModule";

window.addEventListener('load', function () {
    UnsubscribeModule.Init();
});

document.addEventListener('widgetLoaded', function (args) {
    DEBUG && console.info(args);
    if (args.detail.model.Name === "Unsubscribe") {
        DEBUG && console.info('**************************************************');
        DEBUG && console.info('=== Widget Reload Event ===')
        DEBUG && console.info('Element: ', args.detail.element);
        DEBUG && console.info('**************************************************');
        UnsubscribeModule.Init();
    }
});


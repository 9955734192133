import { ApiService as api } from "../Services/APIService";
import { AuthService as auth } from "../Services/AuthService";
import { TemplateService } from "../Services/TemplateService";

const template = 'Unsubscribe';

export class UnsubscribeModule {
    static async Init() {
        DEBUG && console.info('*--> Unsubscribe Module Init');

        var elements = document.querySelectorAll('[data-component="unsubscribe"]');

        elements.forEach(element => {

            let contactGuid = element.getAttribute('data-cg');
            let mode = element.getAttribute('data-mode');

            DEBUG && console.info('**************************************************');
            DEBUG && console.info(`Element ID:              ${element.id}`);
            DEBUG && console.info(`Contact GUID:            ${contactGuid}`);
            DEBUG && console.info(`Mode:                    ${mode}`);
            DEBUG && console.info('**************************************************');

            // Get User Auth Obj for profile mode
            if (mode == "profile") {
                auth.getAuthentication()
                    .then(authObj => {
                        if (authObj) {
                            this.InitComponent(element, contactGuid, authObj, mode);
                        }                        
                    });
            }
            else {
                this.InitComponent(element, contactGuid, null, mode);
            }
        });
    }

    static async InitComponent(element, contactGuid, authObj, mode) {
        DEBUG && console.info('==> Unsubscribe InitComponent()');
        if (!contactGuid) {
            if (authObj) {
                contactGuid = authObj.Contact_GUID;
            }
        }

        var data = await this.GetData(contactGuid);

        var obj = {};
        obj.data = data;
        obj.mode = mode;
        obj.contactGuid = contactGuid;

        if (mode == "profile") {
            obj.publications = await this.GetPublications();
        }

        DEBUG && console.log(obj);

        DEBUG && console.info('==> Unsubscribe Render Template');
        var content = await TemplateService.GetRenderedTemplate(template, obj);

        element.innerHTML = content;

        let self = this;

        const buttons = document.querySelectorAll('.btnUnsubscribe');
        buttons.forEach(button => {
            button.addEventListener('click', (obj) => {
                self.Unsubscribe(obj.target);
            });
        });

        if (mode == "profile") {
            let subscribe = document.querySelectorAll(".btnSubscribe");
            subscribe.forEach(s => {
                s.addEventListener('click', (obj) => {
                    self.Subscribe(obj.target);
                });
            });
        }
    }

    static async GetData(contactGuid) {
        const url = `User/GetContactPublications?contactGuid=${contactGuid}`;
        return await api.getApiDataAsync(url, false);
    }

    static async GetPublications() {
        const url = `Communication/Publications`;
        return await api.getApiDataAsync(url, false);
    }

    static async Subscribe(element) {        
        var contactGuid = element.getAttribute('data-cg');
        var publicationId = element.getAttribute('data-id');

        const url = `Communication/PubSignUp`;
        var payload = {};
        payload.publicationId = publicationId;
        payload.contactGuid = contactGuid;

        await api.postApiDataAsync(url, payload, null, false, false, false);
        this.Init();
    }

    static async Unsubscribe(element) {
        var contactGuid = element.getAttribute('data-cg');
        var publicationId = element.getAttribute('data-id');
        var cpId = element.getAttribute('data-did');

        const url = `Communication/Unsubscribe?contactGuid=${contactGuid}&publicationId=${publicationId}&contactPublicationId=${cpId}`;

        await api.getApiDataAsync(url, false);

        var elementToDelete = document.getElementById(`contactPublication-${cpId}`);
        elementToDelete.style.display = "none";

        this.Init();
    }
}